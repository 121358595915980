import cn from 'classnames';
import { FC } from 'react';

import { Text } from '@components/ui';
import { renderImage } from '@lib/image';

interface Props {
  className?: string;
  icon: {
    url: string;
    alt: string;
  };
  headline?: string;
}

const IconWithLabel: FC<Props> = ({ icon: { url, alt }, headline, className }) => (
  <div className={cn('flex w-full items-center', className)}>
    {renderImage({ url, alt }, { className: 'mr-l' })}
    <Text variant="heading-3">{headline}</Text>
  </div>
);

export default IconWithLabel;
