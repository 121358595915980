import { FC } from 'react';

import { Image, Video } from '@commerce/types/common';
import { Renderer } from '@components/screen/factory';
import { Container, ImageOrVideo, Text } from '@components/ui';
import { renderImage } from '@lib/image';
import { isVideo } from '@lib/video';

import style from './ProductOverview.module.scss';

interface Perks {
  content: {
    internalName: string;
    title: string;
    description: string;
    icon: string;
  };
}

interface ModuleOverview {
  title?: string;
  eyebrow?: string;
  media: Image | Video;
  description?: string;
  subtitle: string;
  perks: Perks[];
  videoThumbnail?: Image;
}

const iconUrl: Record<string, string> = {
  battery: '/svgs/overview/battery.svg',
  dishwasher: '/svgs/overview/dishwasher_icon.svg',
  mobile: '/svgs/overview/mobile.svg',
  qr: '/svgs/overview/qr_icon.svg',
  range: '/svgs/overview/range_icon.svg',
  water: '/svgs/overview/water_icon.svg',
  quotes: '/svgs/overview/quotes_icon.svg',
};

const ProductOverview: FC<ModuleOverview> = ({
  title,
  eyebrow,
  media,
  description,
  subtitle,
  perks,
  videoThumbnail,
}) => {
  const renderSingleProductSection = (sectionTitle: string, attributes: Perks[]) => (
    <>
      <Text color="var(--black)" variant="base-bold" className="uppercase mb-8">
        {sectionTitle}
      </Text>
      <div className="flex flex-col flex-wrap justify-center gap-4 md:flex-row md:gap-8 lg:flex-nowrap lg:gap-3">
        {attributes.map(({ content: { title: perkTitle, description: perkDescription, icon } }) => {
          return (
            <div key={perkTitle} className={style.card}>
              {iconUrl[icon] && (
                <div className={style.icon}>
                  {renderImage({ url: iconUrl[icon], alt: perkDescription }, { 'aria-hidden': true, loading: 'lazy' })}
                </div>
              )}
              <Text color="var(--black)" variant="header-5-book" asElement="p">
                {perkTitle}
              </Text>
              <Text color="var(--gray-50)" variant="base" asElement="p">
                {perkDescription}
              </Text>
            </div>
          );
        })}
      </div>
    </>
  );

  // Function to render the bundle section
  // const isBundle = false;
  // const renderBundleSection = (products: []) => (
  //   <>
  //     <Text color="var(--black)" variant="base-bold" className="uppercase mb-8">
  //       Included in this bundle
  //     </Text>
  //     <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
  //       {products.map(() => (
  //         <div key="DynamicKey" className={style.cardContainer}>
  //           <div className="mx-auto text-center">
  //             <ImageOrVideo type="image" {...productImage} />
  //           </div>
  //           <Text variant="heading-4" className="mb-m" asElement="p">
  //             Tile Sticker
  //           </Text>
  //           {/* Replace FIELD LABEL and FIELD CONTENT with the actual data */}
  //           <div className="mb-s">
  //             <Text className={style.spec} color="var(--gray-50)" variant="header-5-book" asElement="p">
  //               Label
  //             </Text>
  //             <Text className={style.spec} color="var(--gray-50)" variant="header-5-book" asElement="p">
  //               Content
  //             </Text>
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   </>
  // );

  return (
    <div className={style.container}>
      <Container>
        <div className="px-4 py-16 md:px-16">
          <div className="mb-10">
            <Text color="var(--purple)" variant="eyebrow" className="uppercase" asElement="p">
              {eyebrow}
            </Text>
            <Text color="var(--black)" variant="header-1" className={style.subheader} asElement="h2">
              {title}
            </Text>
            {media && (
              <ImageOrVideo
                className={style.media}
                type={isVideo(media.type) ? media.type! : 'image'}
                {...media}
                thumbnail={videoThumbnail?.url || undefined}
                width={664}
              />
            )}
            <Text variant="header-5-book" className="text-left" asElement="p">
              {description}
            </Text>
          </div>

          <div className="py-12">{renderSingleProductSection(subtitle, perks)}</div>
        </div>
      </Container>
    </div>
  );
};

export default Renderer({ name: 'moduleOverview' })(ProductOverview);
