import { faCircle, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { FC, Fragment, useState } from 'react';

import { isPremiumSKU } from '@components/cart/helpers';
import { ModulePlan } from '@components/common/types/ModulePlan';
import { ModuelPlanFeatureValue, ModulePlanFeature } from '@components/common/types/ModulePlanFeature';
import { PremiumSource } from '@components/product/enums';
import { Button, Container, Text, Tooltip } from '@components/ui';
import { useCart } from '@framework/cart';
import { useCustomer } from '@framework/customer';
import { AddToCartFn } from '@lib/hooks/useAddToCart';
import { isEmeaLocale } from '@lib/locales';

import plansStyle from '../plans.module.scss';
import s from './ComparePlans.module.scss';

interface Props {
  title: string;
  plans: ModulePlan[];
  planFeatures: ModulePlanFeature[];
  onAddToCart: AddToCartFn;
  loading: boolean;
}

const AddSusbcription = ({
  loading: disabled,
  onAddToCart,
  id,
  sku,
  cta,
  name,
  disablePremium,
}: Pick<Props, 'loading' | 'onAddToCart'> & {
  cta: string;
  id: number;
  sku: string;
  name: string;
  disablePremium: boolean;
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const { locale = i18n.defaultLocale } = router;
  const renderCTA = !isEmeaLocale(locale);
  const addToCart = async () => {
    if (id && sku) {
      setLoading(true);
      await onAddToCart(id.toString(), sku, name, undefined, PremiumSource.PREMIUM_PAGE);
      setLoading(false);
    }
  };

  return id && renderCTA ? (
    <Button
      className={s.button}
      variant="cta"
      onClick={() => addToCart()}
      aria-label={cta}
      disabled={!id || (disabled && !loading) || disablePremium}
      loading={loading}
      data-cy={`comparePlansTable-${camelCase(name)}-button`}
    >
      {cta}
    </Button>
  ) : null;
};

const ComparePlans: FC<Props> = ({ title, plans, planFeatures, onAddToCart, loading }) => {
  const { t } = useTranslation(['common', 'plans']);

  const { isSubscriber } = useCustomer();
  const { data: cart } = useCart();
  const { locale } = useRouter();

  const hasSubscriptionInCart = cart?.lineItems
    ?.map((item) => item.variant.sku)
    ?.some((sku) => isPremiumSKU(sku, locale));
  const disablePremium = !!(isSubscriber || hasSubscriptionInCart);

  const getContent = (planValue: ModuelPlanFeatureValue) => {
    const defaultValue = (
      <FontAwesomeIcon icon={faTimes} className="text-accents-10" title={t('common:notAvailable')} />
    );

    // assume the default value is that the feature is not included
    if (!planValue) {
      return defaultValue;
    }

    // if there's a text value - render that
    if (planValue.textValue) {
      return (
        <Text variant="text-3" color="var(--text-secondary-2)" weight="bold">
          {planValue.textValue}
        </Text>
      );
    }

    // otherwise check if the feature is included and return the appropriate value
    if (planValue.isIncluded) {
      return <FontAwesomeIcon icon={faCircle} className="text-accents-10" title={t('common:available')} />;
    }

    return defaultValue;
  };

  const getCtaMessage = (defaultCta: string): string => {
    if (isSubscriber) {
      return t('plans:choosePlans.warning.alreadySubscribed_short');
    }
    if (hasSubscriptionInCart) {
      return t('plans:choosePlans.warning.alreadyInCart_short');
    }
    return defaultCta;
  };

  const renderHeader = () => (
    <>
      <div />
      <Text variant="text-2" className={s.planName} asElement="h3">
        {t('label.plan.free')}
      </Text>
      {plans.map(({ yearlyProduct: { bigCommerceId }, name }) => (
        <Text variant="text-2" key={bigCommerceId} className={s.planName} asElement="h3">
          {name}
        </Text>
      ))}
    </>
  );

  const renderBody = () => (
    <>
      {planFeatures.map(({ name, description, planValues }) => (
        <Fragment key={name}>
          <Text variant="text-3" className={cn(s.featureName, 'md:px-0 px-2')}>
            {name}

            <Tooltip
              tooltipContent={description ? <Text color="var(--white)" weight="semibold" html={description} /> : ''}
              className="ml-2 cursor-pointer"
              contentClassName={s.tooltipContent}
              arrowClassName={s.arrowClassName}
              placement="right"
            >
              <FontAwesomeIcon icon={faInfoCircle} aria-label={name} title={name} />
            </Tooltip>
          </Text>

          {planValues?.map((planValue) => (
            <div key={planValue.planId} className={s.borderedCell}>
              {getContent(planValue)}
            </div>
          ))}
        </Fragment>
      ))}
    </>
  );

  const renderFooter = () => (
    <>
      <div />
      <div />
      {plans.map(({ yearlyProduct: { bigCommerceId, sku, name }, getPlanCta }) => (
        <div key={bigCommerceId} className={cn(s.footerCell, 'pt-4')}>
          {bigCommerceId && getPlanCta && (
            <AddSusbcription
              onAddToCart={onAddToCart}
              loading={loading}
              cta={getCtaMessage(getPlanCta)}
              id={bigCommerceId}
              sku={sku}
              name={name}
              disablePremium={disablePremium}
            />
          )}
        </div>
      ))}
    </>
  );

  const renderMobileFooter = () => (
    <div className="flex justify-end">
      {plans.map(({ yearlyProduct: { bigCommerceId, sku, name }, getPlanCta }) => (
        <div key={bigCommerceId} className={s.mobileFooter}>
          {bigCommerceId && getPlanCta && (
            <AddSusbcription
              onAddToCart={onAddToCart}
              loading={loading}
              cta={getCtaMessage(getPlanCta)}
              id={bigCommerceId}
              sku={sku}
              name={name}
              disablePremium={disablePremium}
            />
          )}
        </div>
      ))}
    </div>
  );

  return (
    <Container>
      <Container className={cn(plansStyle.container, 'md:max-w-7xl max-w-[90%]')}>
        <Text variant="heading-2" className={plansStyle.heading}>
          {title}
        </Text>
        <div className="overflow-auto relative md:static">
          <div
            className="grid min-w-[90%]"
            style={{ gridTemplateColumns: `minmax(130px, 1fr) repeat(${plans.length + 1}, minmax(30px, 1fr))` }}
          >
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
          </div>
        </div>
        {renderMobileFooter()}
      </Container>
    </Container>
  );
};

export default ComparePlans;
