import { ExperienceMapper } from '@ninetailed/experience.js-utils';

import { removeEmpty } from '@lib/remove-empty';

import { resolveRenderer } from './factory';
import type { ModuleVariant, NtConfigurable, PlpVariant, ScreenVariant } from './types';

export const SCREEN_PDP_CONTENT_TYPE = 'screenPdp';
const SCREEN_CONTENT_TYPE = 'screen';
const isScreenContentType = (type: string) => type === SCREEN_CONTENT_TYPE;

const mapper = ({ id, content }: NonNullable<NtConfigurable['nt_experiences']>[number]) => ({
  id,
  description: content.nt_description ?? undefined,
  name: content.nt_name,
  config: content.nt_config,
  type: content.nt_type,
  audience: content.nt_audience
    ? {
        id: content.nt_audience.content.nt_audience_id,
        name: content.nt_audience.content.nt_name,
        description: content.nt_audience.content.nt_description,
      }
    : undefined,
});

export const mapScreenExperiences = (nt_experiences?: NtConfigurable['nt_experiences'], screenContentType?: string) => {
  const checkContentType = screenContentType ? (v: string) => v === screenContentType : isScreenContentType;
  return (nt_experiences ?? [])
    .filter((exp) => exp.content)
    .map((exp) => {
      return {
        ...mapper(exp),
        variants: (exp.content?.nt_variants ?? [])
          // Only include variants where the contentType is the same as the Screen content type.
          .filter((v) => checkContentType(v.contentType))
          .map(
            (v): ScreenVariant => ({
              page: { ...removeEmpty(v.content), id: v.id },
              id: v.id,
            })
          ),
      };
    })
    .filter(ExperienceMapper.isExperienceEntry<ScreenVariant>)
    .map(ExperienceMapper.mapExperience<ScreenVariant>);
};

export const mapModuleExperiences = (nt_experiences?: NtConfigurable['nt_experiences']) => {
  return (nt_experiences ?? [])
    .filter((exp) => exp.content)
    .map((exp) => ({
      ...mapper(exp),
      variants: (exp.content.nt_variants ?? [])
        .filter((v) => !isScreenContentType(v.contentType))
        .map((v) => ({
          ...v.content,
          variant: resolveRenderer(v.contentType),
          id: v.id,
        }))
        // Only include variants with a supported variant renderer.
        .filter((v): v is ModuleVariant => !!v.variant?.component && !!v.id),
    }))
    .filter(ExperienceMapper.isExperienceEntry<ModuleVariant>)
    .map(ExperienceMapper.mapExperience<ModuleVariant>);
};

export const mapScreenPlpExperiences = (nt_experiences?: NtConfigurable['nt_experiences']) => {
  return (nt_experiences ?? [])
    .filter((exp) => exp.content)
    .map((exp) => ({
      ...mapper(exp),
      variants: (exp.content.nt_variants ?? [])
        .map(
          (v): PlpVariant => ({
            id: v.id,
            shouldNotUseVariant: Boolean(v.content.shouldNotUseVariant),
          })
        )
        .filter((v): v is PlpVariant => !!v.id),
    }))
    .filter(ExperienceMapper.isExperienceEntry<PlpVariant>)
    .map(ExperienceMapper.mapExperience<PlpVariant>);
};
