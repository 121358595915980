import 'swiper/css/pagination';

import cn from 'classnames';
import React, { FC, useState } from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Image, Video } from '@commerce/types/common';
import { Renderer } from '@components/screen/factory';
import { ImageOrVideo, Text } from '@components/ui';
import EffectMaterial from '@lib/swiper/effects/effect-material.esm';
import { isVideo } from '@lib/video';

import Button from '../Button/Button';

import style from './HeroCarousel.module.scss';

interface SlideContent {
  content: {
    internalName: string;
    header?: string;
    subheader?: string;
    primaryCta?: string;
    primaryUrl?: string;
    secondaryCta?: string;
    secondaryUrl?: string;
    alignment?: 'Left' | 'Center' | 'Right';
    media: Image | Video;
    videoThumbnail: Image;
  };
}
interface ModuleOverview {
  interval: number;
  slides: SlideContent[];
}

const HeroCarousel: FC<ModuleOverview> = ({ slides }) => {
  const [slideProgress, setSlideProgress] = useState(0);
  const onAutoplayTimeLeft = (s: any, time: any, progress: number) => {
    setSlideProgress(() => {
      return 100 * (1 - progress);
    });
  };

  return (
    <div className={style.carouselSection}>
      <Swiper
        className={style.swiperMaterial}
        modules={[Autoplay, Pagination, EffectMaterial]}
        effect="material"
        spaceBetween={10}
        slidesPerView={1}
        speed={600}
        centeredSlides
        loop
        grabCursor
        pagination={{
          el: `.${style.materialPagination}`,
          clickable: true,
          bulletActiveClass: style.materialPaginationBulletActive,
          renderBullet(index, className) {
            return `<span class="${cn(style.materialPaginationBullet, className)}"><div class="${
              style.wormLoader
            }"></div></span>`;
          },
        }}
        autoplay={{
          disableOnInteraction: false,
          delay: 4000,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          800: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
      >
        {slides.map((slide) => (
          <SwiperSlide key={`slide-${slide.content.internalName}`}>
            <div className={cn(style.swiperMaterialWrapper, 'swiper-material-wrapper')}>
              <div className={style.swiperMaterialContent}>
                {slide.content.media && (
                  <ImageOrVideo
                    {...slide.content.media}
                    className={style.materialCarouselMedia}
                    data-swiper-material-scale={1.25}
                    type={isVideo(slide.content.media.type) ? slide.content.media.type! : 'image'}
                    thumbnail={slide.content.videoThumbnail?.url || undefined}
                  />
                )}
                {/* Slide Content class it not being used */}
                <div className={cn(style.materialCarouselCopy, 'swiper-material-animate-opacity')}>
                  {slide.content.header && (
                    <Text variant="heading-2" className="text-white">
                      {slide.content.header}
                    </Text>
                  )}
                  {slide.content.subheader && <Text className="mb-5 text-white">{slide.content.subheader}</Text>}
                  <div className={style.materialCarouselCtas}>
                    {slide.content.primaryCta && (
                      <Button className={style.primaryCta} variant="cta" href={slide.content.primaryUrl}>
                        {slide.content.primaryCta}
                      </Button>
                    )}
                    {slide.content.secondaryCta && (
                      <Button className={style.secondaryCta} variant="cta" href={slide.content.secondaryUrl}>
                        {slide.content.secondaryCta}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className={cn(style.materialPagination, 'swiper-pagination')}
        style={{ '--progress': `${slideProgress}%` } as React.CSSProperties}
      />
    </div>
  );
};

export default Renderer({ name: 'moduleHeroCarousel' })(HeroCarousel);
